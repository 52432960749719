import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    userStatus: '',
    user: '',
    userProfile: '',
    token: localStorage.getItem('token') || '',
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
    user_profile(state, user) {
      state.userProfile = user
    },
    user_success(state, userProfile) {
      state.userProfile = userProfile
    },
    user_error(state) {
      state.userStatus = 'User error'
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: 'https://api.tek-zence.no/auth/local', data: user, method: 'POST' })
          .then(response => {

            const token = response.data.jwt
            const user = response.data.user

            // Add the following line:
            localStorage.setItem('token', token)
            axios.defaults.headers.common['Authorization'] = "Bearer " + token
            
            commit('auth_success', token, user)
            resolve(response)
      
          })
          .catch(err => {
            console.log(err)
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url: 'http://api.tek-zence.no/auth/local/register', data: user, method: 'POST' })
          .then(response => {
            const user = response.data.user

            // Add the following line:
            commit('auth_success', user)
            resolve(response)
          })
          .catch(err => {
            console.log(err)
            commit('auth_error', err)
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userToken: state => state.token,
    userStatus: state => state.user,
    userProfile: state => state.user
  }
})