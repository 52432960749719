import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import AsyncComputed from 'vue-async-computed'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'

import Axios from 'axios'
import i18n from './i18n'
import VueGeolocation from 'vue-browser-geolocation'
import Vue2Filters from 'vue2-filters'
import VueNumerals from 'vue-numerals'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueJsonPretty from 'vue-json-pretty';
import VueDayjs from 'vue-dayjs-plugin'
import VCalendar from 'v-calendar';
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'

import 'vue-json-pretty/lib/styles.css';


// Import BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false


Vue.config.productionTip = false
Vue.use(Axios)
Vue.use(AsyncComputed)
Vue.use(BootstrapVue)
Vue.use(VueGeolocation)
Vue.use(Vue2Filters)
Vue.use(VueJsonPretty)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VCalendar);
Vue.use(VueScrollTo)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueDayjs, {
  filters: {
    ago: 'siden',
  }
})

Vue.use(VueNumerals, {
  locale: 'no'
});

require('dayjs/locale/nb')



Vue.prototype.$http = Axios;

const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + token
}

// Global RTL flag
Vue.mixin({
  data: globals
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
