import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '@/store.js'

import globals from '@/globals'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    { path: '/', redirect: '/digitaltaksering' },
    {
      path: "/",
      name: "home",
      component: () =>
        import("@/components/Home.vue")
    },
    {
      path: "/digitaltaksering",
      name: "digitaltakst",
      component: () =>
        import("@/components/Home.vue")
    },
    {
      path: "/terms",
      name: "terms",
      component: () =>
        import("@/components/Terms.vue")
    },
    {
      path: "/payment/:id",
      name: "payment",
      props: true,
      component: () => import('@/components/Payment.vue')
    },
]
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }

})

export default router
