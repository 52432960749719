<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
export default {
  name: "app",
  metaInfo: {
    title: "Digital taksering - dtakst.no",
    titleTemplate: "Digital taksering - dtakst.no",
     meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0', keywords: "dtakst.no,dtakst.no, Digital taksering, video befaring, online Takstmann, gsuit DTakst, live chat, bygningskyndig, live film, videokonsultasjon, video chat, Digital befaring, online visning, meglerhjelp, online konsultasjon, video befaring" }
    ]
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    userEmail: function () {
      return this.$store.getters.userEmail;
    },
  },
  methods: {
    updated() {
      // Remove loading state
      setTimeout(() => document.body.classList.remove("app-loading"), 1);
    },
    getGeolocation() {
      this.$getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0, //defaults to 0
      }).then((coordinates) => {
        this.geolocation = coordinates;
      });
    },
  },
  created: function () {
    this.updated();
  },
};
</script>

<style lang="css">
.body {
  background: #fdfdfd;
}
</style>
